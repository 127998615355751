<template>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h3>Е.В. Скворцова</h3>
            <!-- &nbsp; &nbsp; &nbsp; &nbsp;  -->
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/sev.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Скворцова Елена Валентиновна</h2>
                <h4>Главный врач, <br> врач-стоматолог-терапевт клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: диплом с отличием, Чувашский государственный университет им.И.Н.Ульянова (1997 г.)
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Сертификат по специальности: Стоматология терапевтическая <br>
Действителен до 24 ноября 2028 г. <br>
Выдан ФГБОУ ВО «Приволжский исследовательский медицинский
университет» Минздрава Российской Федерации, г. Нижний Новгород
                </p>
                <p>
                  Награды: <br>
Медаль «Отличник стоматологии» стоматологической Ассоциации России
                </p>
                <p>
                  Из отзывов пациентов Елены Валентиновны: «Лечусь у неё уже много лет. Переехала в Москву. Также продолжаю к ней ездить», «Сегодня в клинике Экодент была художественная реставрация центрального зуба. Результатом работы очень довольна, я в восторге. Спасибо Елене Валентиновне, она настоящий мастер своего дела. Огромное ей спасибо за её работу, теперь могу широко улыбаться. Всем рекомендую».
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h3>О.Г. Ремнёва</h3>
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/rog.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Ремнёва Ольга Гурьевна</h2>
                <h4>Врач-стоматолог-терапевт клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: Чувашский государственный университет им.И.Н.Ульянова (1999 г.) 
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Аккредитация по специальности: Стоматология терапевтическая <br>
Действительна до 15 октября 2026 г. <br>
Выдана ГАУ ДПО «Институт усовершенствования врачей» Минздрава Чувашии
                </p>
                <p>
                  Награды: <br>
Почетная грамота Минздрава Чувашской Республики, 2005 г.
                </p>
                <p>
                  Из отзывов пациентов Ольги Гурьевны – относится к пациенту «мягко и бережно», «отдельное спасибо нашему доктору Ремневой Ольге Гурьевне, лечимся у нее уже 20 лет - профессионал высокого класса, очень внимательная и чуткая».
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <h3>И.С. Семенова</h3>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/sis3.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Семенова Ирина Сергеевна</h2>
                <h4>Врач-стоматолог-ортодонт клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: Чувашский государственный университет им.И.Н.Ульянова (2018 г.) 
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Ординатура по специальности: Ортодонтия
                </p>
                <p>
                  Диплом выдан ФГБОУ ДПО «Российская медицинская академия непрерывного профессионального образования» Минздрава Российской Федерации, г.Москва
                </p>
                <p>
                  Аккредитация по специальности: Ортодонтия <br>
Действительна до 15 октября 2026 г. <br>
Выдана ГАУ ДПО «Институт усовершенствования врачей» Минздрава Чувашии
                </p>
                <p>
                  Из отзывов пациентов Ирины Сергеевны: «В прошлом году увидела в Контакте в группе стоматологии «ЭКОДЕНТ» информацию об акции на установку брекетов. Решила не упускать эту возможность. Ортодонт в этой клинике - Ирина Сергеевна Семенова. Записалась к ней на прием. Очень довольна результатом. Рекомендую».
                </p>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree2">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2" aria-expanded="false" aria-controls="collapseThree2">
            <h3>Е.М. Сперанская</h3>
          </button>
        </h2>
        <div id="collapseThree2" class="accordion-collapse collapse" aria-labelledby="headingThree2" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/sem2.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Сперанская Екатерина Михайловна</h2>
                <h4>Врач-стоматолог-терапевт клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: Чувашский государственный университет им.И.Н.Ульянова (2015 г.)
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Ординатура: Чувашский государственный университет им.И.Н.Ульянова (2017 г.) <br>
                  Специальность: Стоматология терапевтическая
                </p>
                <p>
                  Аккредитация по специальности: Стоматология терапевтическая <br>
Действительна до 30 июня 2027 г. <br>
Место проведения: ФГБОУ ДПО «Российская медицинская академия 
непрерывного профессионального образования» Минздрава Российской 
Федерации, г.Москва
                </p>
                <p>
                  Из отзывов пациентов Екатерины Михайловны: «Грамотно, оперативно 
устранила все мои проблемы с зубами, привела их в порядок! Теперь за 
красотой своих зубов только к ней!», «Подруга посоветовала обратиться к 
Сперанской Екатерине Михайловне! В течение нескольких лет она у неё 
наблюдается и очень довольна. Довольна осталась и я, чувствуется опыт, 
уверенность, знания и любовь к своей профессии! Теперь я пациент только 
этого врача!»
                </p>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <h3>А.М. Спода</h3>
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/sam.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Спода Андрей Михайлович</h2>
                <h4>Врач-стоматолог-ортопед клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: диплом с отличием, Чувашский государственный университет им.И.Н.Ульянова (1997 г.)
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Сертификат по специальности: Стоматология ортопедическая <br>
Действителен до 31 октября 2025 г. <br>
Выдан ГАУ ДПО «Институт усовершенствования врачей» Минздравсоцразвития Чувашии
                </p>
                <p>
                  Награды: <br>
Медаль «Отличник стоматологии» стоматологической Ассоциации России
                </p>
                <p>
                  Из отзывов пациентов Андрея Михайловича – «за несколько дней до юбилея сломалась коронка на передних зубах, обратилась к своему постоянному врачу Споде А.М. Оперативно и качественно решил мою проблему. Спасибо большое за Вашу работу».
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <h3>В.Ю. Чернов</h3>
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/svu.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Чернов Валерий Юрьевич</h2>
                <h4>Врач-стоматолог-ортопед клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: Чувашский государственный университет им.И.Н.Ульянова (1998 г.)  
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Сертификат по специальности: Стоматология ортопедическая <br>
Действителен до 26 декабря 2025 г. <br>
Выдан ООО «Центр Профессиональной Компетенции», г.Чебоксары
                </p>
                <p>
                  Из отзывов пациентов Валерия Юрьевича – «подробно и доходчиво все объясняет, очень внимателен. Коронки стоят уже несколько лет, все родственники и сам хожу только к нему - очень довольны. Рекомендую».
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            <h3>В.И. Яковлев</h3>
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="kn">
              <div class="dim">
                <img class="sev" src="../assets/jvi.jpg" alt="">
              </div>
              <div class="kno">
                <h2>Яковлев Владислав Иосифович</h2>
                <h4>Врач-стоматолог-хирург клиники «ЭКОДЕНТ»</h4>
                <p>
                  Образование: диплом с отличием, Чувашский государственный университет им.И.Н.Ульянова (2019 г.)  
                </p>
                <p>
                  Специальность по диплому: Стоматология
                </p>
                <p>
                  Ординатура по специальности: Стоматология хирургическая <br>
Диплом выдан ГАУ ДПО «Институт усовершенствования врачей» Минздрава Чувашии
                </p>
                <p>
                  Аккредитация по специальности: Стоматология хирургическая <br>
Действительна до 15 октября 2026 г. <br>
Выдана ГАУ ДПО «Институт усовершенствования врачей» Минздрава Чувашии
                </p>
                <p>
                  Из отзывов пациентов  Владислава Иосифовича – «очень переживала, хотя это не первый зуб мудрости, который нужно удалить. Но само удаление прошло очень комфортно. Ни ощущения, что сейчас вылетит челюсть, ни какого-либо хруста я не почувствовала. Благодаря доктору, я почувствовала спокойствие и уверенность. Хочу заметить, что зуб мудрости нижний, и был утоплен в десне».
                </p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
name: 'Akkord'
}
</script>

<style scoped>

.accordion {
  margin-top: 4vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.accordion-item {
  background: #fff;
}

.accordion-button {
  width: 100%;
  background: #ceface;
  text-shadow: none;
}

button:hover {
  background: white;
  border-color: green;
  color: green;
  transition: all 0.4s ease;
  text-shadow: none;
}

.kn {
  display: grid;  
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.dim {  
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  justify-content: center;
}

.sev {
  width: 27vw;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
}

.kno {
  margin-top: 10px;
}

h2 {
  font-size: 2vw;
}

h3 {
  color: green;
  margin-bottom: 0;
  font-size: 2vw;
}

h4 {
  font-size: 1.5vw;
}

p {
  margin-top: 10px;
  width: 45vw;
  font-size: 1.1vw;
}

@media (max-width: 790px) {

  .kn {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .sev {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    /* margin-top: 30px; */
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 21px;
    margin-bottom: 20px;
  }

  p {
    width: 100%;
    font-size: 18px;
  }

  button {
    width: 100%;
  }

  .accordion-item {
    border-width: 0;
  }

  .accordion-button {
    height: 70px;
  }
    
}

</style>